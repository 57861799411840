.plansHeader {
    display: flex;
    gap: 5rem;
    font-weight: bold;
    font-size: 3rem;
    justify-content: center;
    color: white;
    text-transform: uppercase;
    font-style: italic;
}

.plansContainer {
    margin-top: -4rem;
    padding: 0 2rem;
    display: flex;
    flex-direction: column;
    gap: 4rem;
    position: relative;
}

.plans {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 3rem;
}

.plansBlur1 {
    width: 32rem;
    height: 23rem;
    top: 6rem;
    left: 0;
}

.plansBlur2 {
    width: 32rem;
    height: 23rem;
    top: 10rem;
    right: 0;
}

@media screen and (max-width:768px) {
    .plans {
        flex-direction: column;
    }

    .plans>:nth-child(2) {
        transform: none;
    }
}